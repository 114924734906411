import React from 'react';

import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type FreePrice = {
  allFreePriceYaml: {
    nodes: {
      id: string;
      content: string;
    }[];
  }
}

const query = graphql`
  query {
    allFreePriceYaml {
      nodes {
        id
        content
      }
    }
  }
  `

const FreePrice: React.FC = () => {
  const data = useStaticQuery<FreePrice>(query);

  const freePrice = data.allFreePriceYaml.nodes;
  
  return (
    <Layout>
      <SEO title="Вільна ціна. Складові тарифу" />
      <Container className="general">
        <PageTitle title="Вільна ціна. Складові тарифу" />

        {freePrice.map(({id, content}) => (
          <ExpandableSection key={id} summary={id} innerHTML={content}/>
        ))}
      </Container>
    </Layout>
  )
}

export default FreePrice;